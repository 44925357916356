import { Box, Divider, Heading, Text } from "@chakra-ui/layout"

const ClothesForm = () => {
	return (
		<Box
			w='100%'
			minH={["300px", "400px", "500px", "700px"]}
			bgColor='#FFF'
			p={[4, 6]}
			mb={5}
			borderTopRadius={["none", "none", "none", "4px"]}
			borderBottomRadius={"4px"}
			boxShadow=' 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
		>
			<Heading fontSize={[14, 15, 16, 17]} fontWeight={400}>
				Ubrania
			</Heading>
			<Text fontSize={[13, 14, 15, 16]} color='teal.600'>
				Tutaj można dodać jakąś treść
			</Text>
			<Divider my={2} w='100%' borderColor='#D4D4CD' borderWidth='1px' />
		</Box>
	)
}

export default ClothesForm
